<template>
  <q-dialog v-model="isShowDialog">
    <q-card style="width: 500px; max-width: 70vw">
      <q-card-section class="row items-center justify-between">
        <h6>Cursos Concluídos</h6>
        <q-btn icon="close" flat round dense @click="$emit('closeDialog')" />
      </q-card-section>
      <hr />
      <q-card-section class="column items-center justify-between">
        <div
          class="full-width q-mb-sm"
          v-for="course in courses"
          :key="course.title"
        >
          <CardLastCourses :title="course.title" :idCourse="course.id" />
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
//----COMPONENTS
import CardLastCourses from "@/components/profile/cards/CardLastCourses.vue";

import { ref, watch } from "vue";

export default {
  name: "DialogMoreCourses",
  components: {
    CardLastCourses,
  },
  props: {
    showDialog: Boolean,
    courses: Array,
  },
  emits: ["closeDialog"],
  setup(props) {
    let isShowDialog = ref(props.showDialog);

    watch(
      () => props.showDialog,
      (newState) => {
        isShowDialog.value = newState;
      }
    );

    return {
      isShowDialog,
    };
  },
};
</script>