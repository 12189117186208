
<template>
  <div class="row items-center section-button">
    <div class="button-image row items-center">
      <!-- <q-img
        src="../../../assets/icons/coins.svg"
        class="button-icon q-mx-sm"
        color="white"
        width="20px"
        height="auto"
      /> -->
      <coinsImg class="q-mx-sm" :width="20" :height="20" color="var(--q-secundary)" />
      <small>{{ balance }} Moedas</small>
    </div>
    <router-link to="store">
      <q-img
        src="../../../assets/icons/store.svg"
        class="button-icon q-mx-sm"
        color="white"
        width="23px"
        height="16px"
      />
      Loja
    </router-link>
  </div>
</template>

<script>
//-----IMAGES
import store_img from "../../../assets/icons/store.svg";
import coinsImg from "@/assets/icons/custom/Coins.vue";

//----VUEJS

export default {
  name: "DualColorButton",
  components: {
    coinsImg,
  },
  props: {
    balance: String,
  },

  setup(props) {
    return {
      store_img,
    };
  },
};
</script>

<style lang="scss" scoped>
.section-button {
  box-shadow: 0px 0.25px 1px rgba(0, 0, 0, 0.039),
    0px 0.85px 3px rgba(0, 0, 0, 0.19);

  background: linear-gradient(
    90deg,
    var(--q-grad-1) 0%,
    var(--q-grad-2) 100%
  );
  border-radius: 30px;
  min-width: 207px;
  max-width: 35%;
  height: 45px;

  .button-image {
    background-color: #ffffff;
    color: var(--q-secundary);
    border-radius: 30px;
    width: 60%;
    height: 100%;
    small {
      color: var(--q-secundary);
    }
  }
  .button-icon {
    width: 23px;
    height: 16px;
  }

  a,
  small {
    display: flex;
    align-items: center;

    font-style: normal;
    font-weight: normal;
    font-size: 0.87rem;
    line-height: 14px;

    color: #fdfdfd;
    text-align: center;
    text-decoration: none;
  }
}
</style>
