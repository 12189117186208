<template>
  <div class="profile-footer">
    <div class="progress-bar-main">
      <q-linear-progress
        :style="`width: ${getCurrentProgressLevel(currentValue, maxValue)}% `"
        size="32px"
        :value="1"
        rounded
        :class="['progress-bar', getLevelClassName(className)]"
      >
        <span class="absolute-full flex items-center justify-end">
          <div
            class="button-image row items-center justify-between label-progress"
          >
            <small><b>{{currentValue}}</b> Exp.</small>
          </div>
        </span>
      </q-linear-progress>
    </div>
    <div class="level-bar flex row items-center justify-center">
      <h5 :class="[getLevelClassName(className)]">{{nextLevel}}</h5>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProgressBar",
  props: {
    className: String,
    currentValue: Number,
    maxValue:Number,
    nextLevel:String
  },

  setup(props) {
    //----CONSTANTE

    function getCurrentProgressLevel(progress, exp_level) {
      return exp_level == 0 ? 1 : (progress / exp_level) * 100;
    }

    function getLevelClassName(className) {
      switch (className.toLowerCase()) {
        case "ferro":
          return "iron-class";
        case "bronze":
          return "bronze-class";
        case "prata":
          return "silver-class";
        case "ouro":
          return "gold-class";
        case "platina":
          return "platinum-class";
        case "diamante":
          return "diamond-class";
        case "imortal":
          return "immortal-class";
        default:
          return "wood-class";
      }
    }

    return {
      //----CONSTANTE
      
      //----FUNCTIONS
      getCurrentProgressLevel,
      getLevelClassName
    };
  },
};
</script>

<style lang="scss" scoped>
.profile-footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.progress-bar-main {
  width: 60%;
  height: 32px;

  display: flex;
  flex-direction: column;
  align-content: flex-start;

  padding: 0;
  background: #00000012;
  border: 1px solid #e5e5e5;
  border-radius: 0 60px 60px 134px;

  span {
    margin-right: 4px;
  }
  .progress-bar {
    border-radius: 0 60px 60px 120px;
    //color: #35ddc7;
    //box-shadow: 3px 6px 30px rgba(53, 221, 199, 0.5);
  }

  @media (min-width: 800px) {
    .level-bar {
      justify-content: flex-start;
      display: flex;
      width: 30%;
      background: #ebebeb;
      border-radius: 60px 0px 130px 60px;
    }
  }
}

.level-bar {
  justify-content: flex-start;
  display: flex;
  width: 30%;
  background: #ebebeb;
  border-radius: 60px 0px 130px 60px;
  padding: 0 20px;

  h5 {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 100%;
    /* identical to box height, or 14px */
    //color: #3794a5;
  }
}
@media (min-width: 800px) {
  .level-bar {
    justify-content: flex-start;
    display: flex;
    width: 30%;
    background: #ebebeb;
    border-radius: 60px 0px 130px 60px;
  }
}

.label-progress {
  background: white;
  border-radius: 40px;
  padding: 3px 18px;

  small {
    font-style: normal;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    color: #000000b3;
  }
}

.wood-class{
  color: #8B8B8B;
}
.iron-class{
  color: #0B27FD;
}
.bronze-class{
  color:#E27F55;
}
.silver-class{
  color:#8B8B8B;
}
.gold-class{
  color:#F0AA26;
}
.platinum-class{
  color:#3794A5;
}
.diamond-class{
  color:#9463C6;
}
.immortal-class{
  color:#9E1524;
}
</style>
