<template>
  <section>
    <div class="profile-image col-3">
      <q-icon
        class="avatar"
        v-if="avatar == ''"
        name="account_circle"
        size="85px"
      />
      <q-img
        v-else
        class="avatar"
        :src="url_aws_bucket + avatar"
        spinner-color="white"
      >
        <template v-slot:error>
          <q-icon class="avatar" name="account_circle" size="85px" />
        </template>
      </q-img>
    </div>
    <slot name="action-btn"></slot>
    <!-- <q-btn
      class="icon-photo"
      size="10px"
      round
      icon="o_photo_camera"
      @click="sendEventAddImage"
    />
    <input
      style="display: none"
      ref="refFileInput"
      @change="target => $emit('onClick', target)"
      type="file"
      name="upload"
      accept="image/*"
    /> -->
  </section>
</template>

<script>
//----UTILS
import { ENV  } from "@/utils/env";

//----VUEJS
import { ref } from "vue";

export default {
  name: "ImageFloatButton",
  props: {
    avatar: String,
  },
  emits: ["onClick"],
  setup(props) {
    //----CONSTANTE
    const refFileInput = ref(null);

    //----VARIABLES
    let url_aws_bucket =ENV.URL_AWS_BUCKET;


    function sendEventAddImage(){
      refFileInput.value.click();
    };

    return {
      //----CONSTANTE
      refFileInput,

      //----VARIABLES
      url_aws_bucket,

      //----FUNCTIONS
      sendEventAddImage
      
    };
  },
};
</script>

<style lang="scss" scoped>
section {
  position: relative;
  .profile-image {
    overflow: hidden;
    height: 85px;
    width: 85px;
    padding: 3px;
    border-radius: 85px;
    position: relative;
    .avatar {
      height: 95%;
      max-width: 95%;
      border-radius: 100%;
      position: absolute;
      left: 1.5%;
      top: 2.5%;
    }
  }
  .icon-photo {
    position: absolute;
    bottom: 0;
    right: 0;
    background: var(--q-primary, #fe4e64);
    color: #fff;
  }
}
</style>
