<template>
  <div class="edit-content q-mt-md">
    <header class="profile-header row items-center">
      <ImageFloatButton
        :avatar="dataProfileUser.userInfo.avatar_location"
        @onClick="onEventPhotoPicked"
      >
        <template v-slot:action-btn>
          <q-btn
            class="icon-photo"
            size="10px"
            round
            icon="edit"
            to="/edit-student"
          />
        </template>
      </ImageFloatButton>
      <div class="profile-name q-ml-md">
        <h5>
          {{
            dataProfileUser.userInfo.first_name +
            " " +
            dataProfileUser.userInfo.last_name
          }}
        </h5>
        <small>{{ dataProfileUser.userInfo.role }}</small>
      </div>
      <div class="row">
        <q-btn
          flat
          rounded
          color="default-pink"
          size="12px"
          icon-right="help_outline"
        >
          <q-menu>
            <q-list style="min-width: 100px">
              <q-item clickable v-close-popup>
                <q-item-section>
                  <a href="http://www.wtf.inf.br/politica.php" target="_blank">
                    Política de privacidade
                  </a>
                </q-item-section>
                
              </q-item>
              <q-item clickable v-close-popup>
                <q-item-section>
                  <a :href="url_aws_bucket + 'files/Termo_de_Uso_Lorni.docx'" download >Termos de uso</a>
                </q-item-section>
              </q-item>
              
            </q-list>
          </q-menu>
        </q-btn>

        <DualColorButton :balance="dataProfileUser.userInfo.balance" />
      </div>
    </header>
    <main>
      <div class="profile-main flex row items-center q-py-sm">
        <div class="profile-level row items-start justify-center">
          <div class="profile-progress column items-start justify-center">
            <h5>Seu desempenho</h5>
            <div v-for="task in dataProfileUser.tasks" :key="task.title">
              <TaskProgress
                :title="task.title"
                :decription="task.description"
                :value="task.quantity"
                :porcent="task.porcent"
              />
            </div>
          </div>
          <hr class="divider" height="250" />
          <div class="profile-progress column items-start justify-center">
            <div class="full-width flex row justify-between items-baseline">
              <h5>Últimos cursos concluídos</h5>
              <small
                v-if="CoursesConcluded.length > 3"
                @click="() => (moreCourses = true)"
                >Ver mais</small
              >
            </div>
            <div
              class="full-width q-mb-sm"
              v-for="(course, index) in CoursesConcluded"
              :key="course.title"
            >
              <CardLastCourses
                v-if="index < 3"
                :title="course.title"
                :idCourse="course.id"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="carousel-level">
        <div class="cards-level q-mb-sm" id="LevelScroll">
          <div v-for="(item, index) in userLevels" :key="index">
            <CardLevel
              class="q-mx-sm"
              :selected="item.is_user_level"
              :levelName="item.class_name + ' ' + item.level_name"
              :levelNumber="item.level_name"
              :className="item.class_name"
            />
          </div>
        </div>
        <div class="navegation-level-left">
          <q-btn
            outline
            round
            icon="chevron_left"
            @click="handleScrollleft()"
            color="default-pink"
          />
        </div>
        <div class="navegation-level-right">
          <q-btn
            outline
            round
            icon="chevron_right"
            @click="handleScrollRight()"
            color="default-pink"
          />
        </div>
      </div>
    </main>
    <footer class="full-width">
      <ProgressBar
        :className="dataProfileUser.className"
        :currentValue="dataProfileUser.level_progress"
        :maxValue="dataProfileUser.level_current_exp"
        :nextLevel="dataProfileUser.next_level_name"
      />
    </footer>
    <DialogMoreCourses
      :showDialog="moreCourses"
      :courses="CoursesConcluded"
      @closeDialog="() => (moreCourses = false)"
    />
  </div>
</template>

<script>
//----COMPONENTS
import TaskProgress from "@/components/TaskProgress.vue";

import DualColorButton from "@/components/profile/buttons/DualColorButton.vue";
import ImageFloatButton from "@/components/profile/buttons/ImageFloatButton.vue";
import CardLastCourses from "@/components/profile/cards/CardLastCourses.vue";
import CardLevel from "@/components/profile/cards/CardLevel.vue";
import ProgressBar from "@/components/profile/progreesbar/ProgressBar.vue";
import DialogMoreCourses from "@/components/profile/dialog/DialogMoreCourses.vue";

//----UTILS
import { ENV  } from "@/utils/env";
import UserServices from "@/services/UserServices";
import TrailDataServices from "@/services/TrailDataServices";

//----VUEJS
import { ref, onMounted } from "vue";
import { useStore } from "vuex";

export default {
  name: "ProfileUser",
  components: {
    TaskProgress,
    DualColorButton,
    ImageFloatButton,
    CardLastCourses,
    CardLevel,
    ProgressBar,
    DialogMoreCourses,
  },

  setup() {
    //----CONSTANTES
    const store = useStore();
    const url_aws_bucket =ENV.URL_AWS_BUCKET;
    const UserService = new UserServices();
    const trailDataServices = new TrailDataServices();
    const breadcrumbs = ref(["Editar Perfil"]);

    //----VARIABLES
    let dataProfileUser = ref({
      coins: 0,
      level: 1,
      level_progress: 0,
      level_current_exp: 0,
      className: "",
      level_name: "Madeira I",
      level_current: 19,
      next_level_name: "Madeira II",
      userInfo: {},
      tasks: [
        {
          title: "wikis",
          description: "Quantidade de Wikis acessadas",
          quantity: "0",
          porcent: 0,
        },
        // {
        //   title: "Cursos concluidos",
        //   description: "Cursos acessados já concluídos",
        //   quantity: "0 / 0",
        //   porcent: 0,
        //   //"Análise baseada na assiduidade dos conteudos"
        // },
        {
          title: "XP total",
          description: "Sua pontuação atual",
          quantity: "0",
          porcent: 0,
        },
      ],
    });
    let moreCourses = ref(false);
    let CoursesConcluded = ref([]);
    let userLevels = ref([]);

    onMounted(() => {
      _getUserValues();
      _getUserIndicator();
      _getCourses();
    });

    function _getUserValues() {
      dataProfileUser.value.userInfo = store.state.userdata;
    }

    async function _getCourses() {
      const result = await trailDataServices.getAll();
      result.data.forEach((course) => {
        if (course.finished == 1) {
          CoursesConcluded.value.push(course);
        }
      });
    }

    async function _getUserIndicator() {
      var responseLevel = await UserService.userLevels();
      userLevels.value = responseLevel.data;
      var response = await UserService.getUserIndicators();

      if (response.data.class_name != null) {
        dataProfileUser.value.className = response.data.class_name;
        dataProfileUser.value.level_name =
          response.data.class_name + " " + response.data.level_name;
      }

      if (response.data.number_wiki_started != null) {
        dataProfileUser.value.tasks[0].quantity = `${response.data.number_wiki_completed} / ${response.data.number_wiki_started}`;
        dataProfileUser.value.tasks[0].porcent =
          (response.data.number_wiki_completed /
            response.data.number_wiki_started) *
          100;
      }

      if (response.data.number_courses_started != null) {
        dataProfileUser.value.tasks[1].quantity = `${response.data.number_courses_completed} / ${response.data.number_courses_started}`;
        dataProfileUser.value.tasks[1].porcent =
          (response.data.number_courses_completed /
            response.data.number_courses_started) *
          100;
      }

      if (response.data.number_courses_started != null) {
        dataProfileUser.value.tasks[2].quantity =
          response.data.progression_points;
        dataProfileUser.value.tasks[2].porcent =
          response.data.progression_points;
      }

      if (response.data.next_points_progression != 0) {
        dataProfileUser.value.next_level_name = response.data.next_level_name;
        dataProfileUser.value.level_progress = response.data.progression_points;
        dataProfileUser.value.level_current_exp =
          response.data.next_points_progression;
        dataProfileUser.value.userInfo.balance = response.data.balance;
      }

      var userdata = store.state.userdata;
      userdata.progress =
        (dataProfileUser.value.level_progress /
          dataProfileUser.value.level_current_exp) *
        100;
      store.commit("setUserData", userdata);
    }

    async function handleScrollRight() {
      var myElement = document.getElementById("LevelScroll");
      if ((myElement.scrollLeft += 300 < myElement.scrollWidth)) {
        myElement.scrollLeft += 300;
      } else {
        myElement.scrollLeft = myElement.scrollWidth;
      }
    }

    async function handleScrollleft() {
      var myElement = document.getElementById("LevelScroll");
      if ((myElement.scrollLeft -= 300 < myElement.scrollWidth)) {
        myElement.scrollLeft -= 300;
      } else {
        myElement.scrollLeft = 0;
      }
    }

    return {
      //----VARIABLES
      moreCourses,
      breadcrumbs,
      dataProfileUser,
      url_aws_bucket,
      userLevels,
      CoursesConcluded,

      //----FUNCTIONS
      handleScrollRight,
      handleScrollleft,
    };
  },
};
</script>

<style lang="scss" scoped>
.edit-content {
  width: 100%;
  height: 95vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;
  padding-bottom: 1%;

  main {
    height: 78vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;

    .carousel-level {
      position: relative;
      .cards-level {
        padding: 11px 8px;

        width: 76vw;

        display: flex;
        flex-direction: row;
        align-items: center;
        overflow-x: scroll;
      }
      .navegation-level-left {
        position: absolute;
        top: 40%;
        left: 10px;
      }

      .navegation-level-right {
        position: absolute;
        top: 40%;
        right: 10px;
      }
    }
  }

  a {
    text-decoration: none;
  }

  @media (min-width: 800px) {
    flex-direction: column;
  }
}

.profile-header {
  padding: 0.5% 1%;
  margin: 0 2%;
  border: 1px solid rgba(0, 0, 0, 0.07);
  border-radius: 45px;

  .icon-photo {
    position: absolute;
    bottom: 0;
    right: 0;
    background: var(--q-secundary);
    color: #fff;
  }
}

.profile-name {
  flex: 1;
  h5 {
    font-style: normal;
    font-weight: bold;
    font-size: 1.37rem;
    line-height: 100%;
    color: #000000;
    margin: 1% 0;
  }
  small {
    font-style: normal;
    font-weight: normal;
    font-size: 1.12rem;
    line-height: 100%;
    color: #000000;
  }
}

.profile-main {
  flex: 1;
  flex-direction: column;
  width: 100%;

  .profile-level,
  .profile-progress {
    flex: 1;
    justify-content: space-around;
  }

  .profile-progress {
    max-width: 100%;

    h5 {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 100%;

      /* Light/Grey5 */
      color: #2f2f2f;

      margin-bottom: 15px;
    }

    small {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;

      /* Light/Grey3 */
      color: #9c9c9c;
      cursor: pointer;
    }
  }

  @media (min-width: 800px) {
    .profile-progress {
      max-width: 40%;
    }
    flex-direction: row;
  }
}

.profile-level {
  hr {
    height: 200px;
    width: 1px;
    display: block;
    border: none;
    background: #00000036;
  }
}
</style>
