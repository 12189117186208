<template>
  <div :class="['card-level', selected ? 'card-size-big' : 'card-size-small']">
    <levelOneIcon v-if="levelNumber == 'I'" class="icon-level" :level="className"/>
    <levelTwoIcon v-if="levelNumber == 'II'" class="icon-level" :level="className"/>
    <levelThreeIcon v-if="levelNumber == 'III'" class="icon-level" :level="className"/>
    <h5>{{levelName}}</h5>
 </div>
</template>

<script>
//----COMPONENTS
import levelOneIcon from '@/components/shared/levelsIcons/LevelOne.vue';
import levelTwoIcon from '@/components/shared/levelsIcons/LevelTwo.vue';
import levelThreeIcon from '@/components/shared/levelsIcons/LevelThree.vue';

export default {
  name: 'CardLevel',
  components: {
    levelOneIcon,
    levelTwoIcon,
    levelThreeIcon
  },
  props:{
    selected:{
      type:Boolean,
      default: false
    },
    levelName:String,
    levelNumber:String,
    className: String
  }
}
</script>

<style lang="scss" scoped>
.card-level{
  width: 180px;
  min-width: 180px;
  height: 180px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  padding: 8px;
  background: #FFFFFF;
  mix-blend-mode: normal;

  border: 1px solid #E5E5E5;
  box-shadow: 0px 0.5px 5px rgba(0, 0, 0, 0.039), 0px 3.75px 11px rgba(0, 0, 0, 0.19);
  border-radius: 16px;

  h5{
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    /* identical to box height, or 16px */

    text-align: center;
  }
}

.card-size-small{
  width: 120px;
  min-width: 120px;
  height: 120px;
  opacity: 0.5;

  .icon-level{
    width: 65.33px;
    height: 65.33px;
  }

  h5{
    font-size: 12px;
  }
}

.card-size-big{
  width: 180px;
  min-width: 180px;
  height: 180px;

  .icon-level{
    width: 98px;
    height: 98px;
  }
  h5{
    font-size: 16px;
  }
}
</style>
